import type { SingleStore } from '@mybonus/public';
import { useAccountContext, useAuthContext } from '@mybonus/ui';
import { useState } from 'react';

import {
	Wrapper,
	OrderButton,
	OfferSelect,
	$BankIdButton,
	RegisterText,
} from './GiftcardPurchase.parts';

export type GiftcardPurchaseProps = {
	store: SingleStore;
};

export function GiftcardPurchase(props: GiftcardPurchaseProps) {
	const auth = useAuthContext();
	const { store } = props;
	const account = useAccountContext();
	const [offerId, setOfferId] = useState<number | undefined>(store.offers[0]?.id);

	return (
		<Wrapper>
			<OfferSelect
				value={offerId}
				placeholder="Välj presentkortets värde"
				options={store.offers.map((o) => ({
					id: o.id,
					label: `${o.spec.label} (${o.override?.spec.text || o.spec.text} återbäring)`,
				}))}
				onChange={(newOfferId) => setOfferId(Number(newOfferId))}
			/>
			{auth.account && !account.isIdentified ? (
				<$BankIdButton
					onIdentified={account.invalidate}
					reason="Du måste identifiera dig för att köpa presentkort."
				/>
			) : (
				<OrderButton disabled={account.isLoading || !account.isIdentified} offerId={offerId} />
			)}
			{!auth.account && (
				<RegisterText>Du måste vara inloggad medlem för att köpa presentkort</RegisterText>
			)}
		</Wrapper>
	);
}
